import React from "react"
import { ReadOutlined } from "@ant-design/icons"
import { Icon as LegacyIcon } from "@ant-design/compatible"
import { Button, Col, Tag, Tooltip, Typography } from "antd"
import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import moment from "moment"
import createURI from "../../utils/createURI"
import style from "./BlogList.module.scss"

export class BlogCard extends React.Component {
  constructor(props) {
    super(props)
    const { content, slug, title } = this.props.post
    const uri = slug || createURI(title)
    const ttr = content.childMarkdownRemark.timeToRead

    this.state = {
      uri: uri,
      path: `/${uri}/`,
      ttrStr: `${ttr} min${ttr > 1 ? `s` : ``}`,
    }
  }

  lastRead = uri => {
    if (typeof window === "undefined") {
      return
    }
    const readList = localStorage.getItem("blogsRead")
    if (readList !== null && readList.includes(uri)) {
      const sec = JSON.parse(readList)[uri]
      return (
        <Tooltip
          className={style.lastRead}
          title={`You last read this article ${moment(sec).format(
            "MMMM Do, YYYY"
          )}`}
        >
          <Tag color="volcano">
            <ReadOutlined />
            {` Read`}
          </Tag>
        </Tooltip>
      )
    }
    return <></>
  }

  render() {
    const {
      content,
      featuredImage,
      id,
      publishedAt,
      tags,
      title,
    } = this.props.post

    const {
      buttonText,
      excerptRows,
      hideDate,
      hideExcerpt,
      hideImage,
      hideReadTag,
      hideTimeToRead,
      hideTitle,
      titleRows,
    } = this.props

    const { postCol, showPost } = this.props

    const { path, ttrStr, uri } = this.state

    const colProps = postCol || {
      xs: 22,
      md: 11,
      lg: 7,
      xl: 6,
      key: id,
    }

    const imgProps = {
      alt: title,
      className: style.image,
    }

    return (
      <Col
        {...colProps}
        className={`${style.blogsWrapper} ${showPost ? showPost(tags) : ``}`}
      >
        {hideImage ? null : (
          <Link to={path}>
            {featuredImage ? (
              <Img {...imgProps} fluid={featuredImage.fluid} />
            ) : (
              <span>no image</span>
            )}
          </Link>
        )}
        {hideTitle ? null : (
          <Link to={path}>
            <Typography.Title
              ellipsis={{
                expandable: false,
                rows: titleRows || 2,
              }}
              level={2}
            >
              {title}
            </Typography.Title>
          </Link>
        )}
        {hideDate && hideReadTag ? null : (
          <Typography.Title level={4} className={style.postInfo}>
            {hideDate ? (
              ``
            ) : (
              <span style={{ marginRight: 8 }}>{publishedAt}</span>
            )}
            {hideReadTag ? `` : this.lastRead(uri)}
          </Typography.Title>
        )}
        {hideExcerpt ? null : (
          <Typography.Paragraph
            ellipsis={{
              expandable: false,
              rows: excerptRows || 4,
            }}
          >
            {content.childMarkdownRemark.excerpt}
          </Typography.Paragraph>
        )}
        <Button type="ghost">
          <Link to={path}>{`${buttonText || `Keep Reading`}${
            hideTimeToRead ? `` : ` (${ttrStr})`
          }`}</Link>
        </Button>
      </Col>
    )
  }
}

class BlogList extends React.Component {
  state = {
    filter: [],
    tags: [],
  }

  componentDidMount() {
    let tags = []
    this.props.nodes.forEach(node => {
      if (node.tags) {
        node.tags.forEach(tag => {
          tags.push(tag.toLowerCase().trim())
        })
      }
    })
    this.setState({
      tags: [...new Set(tags.sort((a, b) => (a - b ? 1 : -1)))],
    })
  }

  toggleFilter = tag => {
    let f = this.state.filter
    const _tag = tag.toLowerCase()
    if (!f.includes(_tag)) {
      f.push(_tag)
    } else {
      f.splice(f.indexOf(_tag), 1)
    }
    this.setState(
      {
        filter: f,
      },
      () => console.log(this.state.filter)
    )
  }

  isChecked = tag => {
    return this.state.filter.includes(tag.toLowerCase())
  }

  tagIcon = tag => {
    const iconProps = {
      className: style.tagIcon,
      type: this.isChecked(tag) ? `stop` : `search`,
    }
    return <LegacyIcon {...iconProps} />
  }

  showPost = tagArr => {
    const { filter } = this.state
    if (filter.length === 0) {
      return style.show
    }
    const _tagArr = tagArr.map(f => f.toLowerCase())
    const show = filter.every(fltr => _tagArr.includes(fltr))
    return show ? style.show : style.hide
  }

  render() {
    return (
      <>
        <Col className={style.tagCol} span={22}>
          <Typography.Title level={4}>Filter Posts</Typography.Title>
          {this.state.tags.map(tag => (
            <Tag.CheckableTag
              className={this.isChecked(tag) ? style.checked : style.unchecked}
              key={tag}
              checked={() => this.isChecked(tag)}
              onChange={() => this.toggleFilter(tag)}
            >
              {this.tagIcon(tag)}
              {tag}
            </Tag.CheckableTag>
          ))}
        </Col>
        {this.props.nodes.map((node, index) => (
          <BlogCard key={index} post={node} showPost={this.showPost} />
        ))}
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        ...AllBlogPosts
      }
    `}
    render={data => <BlogList nodes={data.blogPosts.nodes} />}
  />
)
