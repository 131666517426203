import React from "react"
import { Col, Row } from "antd"
import { graphql } from "gatsby"
import Shell from "../components/Shell/Shell"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import FAQs from "../components/FAQs/FAQs"
import BlogReel from "../components/BlogReel/BlogReel"

const description = `Have questions about The Residences at Biltmore and our luxury hotel suites? Find more information on services, amenities, location, directions and much more!`

const rowProps = {
  align: "middle",
  gutter: 32,
  justify: "center",
  type: "flex",
  style: {
    margin: `auto`,
    maxWidth: 1360,
  },
}

const fullColProps = {
  xs: 22,
  md: 20,
  lg: 18,
  xl: 16,
}

export default ({ data, location }) => {
  const { hero } = data

  return (
    <Shell>
      <SEO
        meta={{
          description,
          image: hero.fluid.src,
          imageAlt: hero.description,
        }}
        title="FAQ's and More Information - The Residences at Biltmore"
        url={location.href}
      />
      <Hero
        caption={
          <span>
            <br />
            Frequently Asked Questions
          </span>
        }
        contentfulAsset={data.hero}
        height={`40vh`}
        imageStyle={{ filter: `brightness(0.65)` }}
      />
      <BookingBar showCTAButtons={false} />
      <Row {...rowProps}>
        <Col {...fullColProps}>
          <FAQs faqs={data.faqs.nodes} />
        </Col>
      </Row>
      <BlogReel numPosts={3} />
    </Shell>
  )
}

export const query = graphql`
  query {
    faqs: allContentfulFrequentlyAskedQuestion(
      filter: {
        business: { elemMatch: { name: { eq: "Residences at Biltmore" } } }
      }
      sort: { fields: priority, order: ASC }
    ) {
      nodes {
        category
        question
        answer {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    hero: contentfulAsset(title: { eq: "RAB - Exterior 3 Featured" }) {
      ...HeroFluid
    }
  }
`
