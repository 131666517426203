import React from "react"
import { Col, Row, Typography } from "antd"
import { graphql, StaticQuery } from "gatsby"
import { BlogCard } from "../../components/BlogList/BlogList"
import style from "./BlogReel.module.scss"

const filterPosts = (posts, field, value) =>
  posts.filter(post => post[field] !== value)

export const BlogReel = props => (
  <Row
    align="middle"
    className={style.wrapper}
    gutter={0}
    justify="center"
    type="flex"
  >
    <Col span={24}>
      <div className={style.heading}>
        <Typography.Title level={3}>
          {props.title ? props.title : `Recent News`}
        </Typography.Title>
      </div>
    </Col>
    {props.posts.slice(0, props.numPosts).map((post, index) => (
      <BlogCard
        buttonText={props.buttonText || "Read Post"}
        className={style.card}
        excerptRows={props.excerptRows || 2}
        hideReadTag={props.hideReadTag || true}
        hideTimeToRead={props.hideTimeToRead || true}
        key={index}
        post={post}
        postCol={props.postCol}
        titleRows={props.titleRows || 1}
      />
    ))}
  </Row>
)

export default props => (
  <StaticQuery
    query={graphql`
      {
        blogs: allContentfulBlogPost(
          filter: { hotel: { name: { eq: "Residences at Biltmore" } } }
          sort: { fields: publishedAt, order: DESC }
        ) {
          nodes {
            title
            publishedAt(formatString: "MMM DD, YYYY")
            id
            content {
              childMarkdownRemark {
                timeToRead
                excerpt
              }
            }
            slug
            featuredImage {
              fluid(maxWidth: 350) {
                ...fluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    `}
    render={data => (
      <BlogReel
        {...props}
        postCol={{
          xs: 22,
          md: Math.floor(22 / props.numPosts),
        }}
        posts={
          props.filterField && props.filterValue
            ? filterPosts(
                data.blogs.nodes,
                props.filterField,
                props.filterValue
              )
            : data.blogs.nodes
        }
      />
    )}
  />
)
